import { HTTP_CONTENT_TYPE, HTTP_HEADER_NAME } from '@config/net';
import { httpFetch, HttpFetchCanceledError } from '@services/base/http-fetch';
import { JsonFetchConfig, JsonFetchHeaders, JsonFetchOwnHeaders, JsonFetchResult } from './types';
export const jsonOwnHeaders = {
  [HTTP_HEADER_NAME.ACCEPT]: HTTP_CONTENT_TYPE.JSON,
  [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE.JSON
};
export const jsonFetch = <R, D = undefined, P = undefined, H extends JsonFetchHeaders = undefined>(url: string, config?: JsonFetchConfig<D, P, H>): JsonFetchResult<R> => {
  const nextConfig = {
    ...config,
    headers: {
      ...jsonOwnHeaders,
      ...config?.headers
    }
  };
  const {
    promise,
    cancel
  } = httpFetch<R, D, P, JsonFetchOwnHeaders | (JsonFetchOwnHeaders & H)>(url, nextConfig);
  return {
    promise: promise.then(({
      data
    }) => data),
    cancel
  };
};
export const JsonFetchCanceledError = HttpFetchCanceledError;