export enum ANALYTICS_CLIENT_TYPE {
  UNDEFINED = 0,
  DESKTOP_BROWSER = 1,
  MOBILE_BROWSER = 2,
  TABLET_BROWSER = 3,
}
export enum ANALYTICS_COMMAND_TRIGGER {
  USER_ACTION = 1,
  // use this on client
  INTERNAL_RUNTIME = 2,
}
export enum ANALYTICS_UIID {
  CHAT_INVITE_BUTTON = 'ChatInviteButton',
  MESSAGE_CREATE_BUTTON = 'MessageCreateButton',
  AUDIO_CALL_BUTTON = 'AudioCallButton',
  VIDEO_CALL_BUTTON = 'VideoCallButton',
  APPOINTMENT_SET_BUTTON = 'AppointmentSetButton',
  GIFT_SENT_BUTTON = 'GiftSentButton',
  BUY_BUTTON_30 = 'BuyButton30',
  BUY_BUTTON_50 = 'BuyButton50',
  USER_VIDEO_PLAY_BUTTON = 'UserVideoPlayButton',
  SEARCH_LOAD_MORE = 'Search.LoadMore',
  SEARCH_PAGE_NUMBER = 'Search.Pagination',
}
export enum ANALYTICS_MODAL_ID {
  CALENDAR = 'Calendar',
  CALENDAR_COINS_RESERVATION = 'Calendar.CoinsReservation',
  CALENDAR_LACK_OF_COINS_FOR_RESERVATION = 'Calendar.LackOfCoinsForReservation',
}
export type AnalyticsVisitId = string;
export type AnalyticsAmplitudeSessionId = string;
export type AnalyticsBaseData = {
  url: string; // url with query
  clientType: ANALYTICS_CLIENT_TYPE;
};