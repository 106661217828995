import { computeAnalyticsBaseData } from '@helpers/logging/analytics/common';
import { readRealtimeSessionId, writeRealtimeSessionId } from '@storage/realtime';
import { realtimeOffline } from '@services/realtime/realtime-offline';
import { ONLINE_STATUS } from '@types';
import { onlineSetStatus } from '@slices/online';
import { geometryGetType } from '@selectors/geometry';
import { RealtimeFetchOffline } from './types';
export const realtimeFetchOffline: RealtimeFetchOffline = payload => {
  return async (dispatch, getState) => {
    const {
      storage,
      navigator
    } = payload;
    const realtimeSessionId = readRealtimeSessionId(storage);
    if (!realtimeSessionId) {
      return;
    }
    const state = getState();
    const geometryType = geometryGetType(state);
    let data;
    try {
      data = await realtimeOffline({
        sessionId: realtimeSessionId,
        ...computeAnalyticsBaseData({
          navigator,
          geometryType
        })
      }).promise;
    } finally {
      dispatch(onlineSetStatus({
        status: ONLINE_STATUS.OFFLINE
      }));
      writeRealtimeSessionId(storage, undefined);
    }
    return data;
  };
};