module.exports.colors = {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  black: 'hsl(0, 0%, 0%, 1)',
  'black-0-0-1': 'hsl(0, 0%, 0%, 0.01)',
  'black-0-0-10': 'hsl(0, 0%, 0%, 0.1)',
  'black-0-0-20': 'hsl(0, 0%, 0%, 0.2)',
  'black-0-0-40': 'hsl(0, 0%, 0%, 0.4)',
  'black-0-0-50': 'hsl(0, 0%, 0%, 0.5)',
  'black-0-0-70': 'hsl(0, 0%, 0%, 0.7)',
  'blue-1-28-100': 'hsl(210, 1%, 28%, 1)',
  'blue-31-90-100': 'hsl(188, 31%, 90%, 1)',
  'blue-47-88-100': 'hsl(191, 47%, 88%, 1)',
  'blue-73-94-100': 'hsl(213, 73%, 94%, 1)',
  'blue-78-51-100': 'hsl(212, 78%, 51%, 1)',
  'blue-81-90-100': 'hsl(213, 81%, 90%, 1)',
  'blue-86-67-04': 'hsl(212, 86%, 67%, 0.4)',
  'blue-88-61-10': 'hsl(212, 88%, 61%, 0.1)',
  'blue-88-61-20': 'hsl(212, 88%, 61%, 0.2)',
  'blue-88-61-30': 'hsl(212, 88%, 61%, 0.3)',
  'blue-88-61-40': 'hsl(212, 88%, 61%, 0.4)',
  'blue-88-61-50': 'hsl(212, 88%, 61%, 0.5)',
  'blue-88-61-80': 'hsl(212, 88%, 61%, 0.8)',
  'blue-88-61-90': 'hsl(212, 88%, 61%, 0.9)',
  'blue-88-61-100': 'hsl(212, 88%, 61%, 1)',
  'blue-98-71-100': 'hsl(212, 98%, 71%, 1)',
  'blue-27-87-70': 'hsl(217, 27%, 87%, 0.7)',
  'blue-27-87-100': 'hsl(217, 27%, 87%, 1)',
  'blue-36-96-100': 'hsl(216, 36%, 96%, 1)',
  'blue-38-80-100': 'hsl(215, 38%, 80%, 1)',
  'blue-40-96-100': 'hsl(218, 40%, 96%, 1)',
  'blue-49-61-100': 'hsl(215, 49%, 61%, 1)',
  'blue-42-55-20': 'hsl(216, 42%, 55%, 0.2)',
  'blue-63-30-100': 'hsl(219, 63%, 30%, 1)',
  'blue-90-39-50': 'hsl(219, 90%, 39%, 0.5)',
  'blue-90-39-100': 'hsl(219, 90%, 39%, 1)',
  'blue-27-96-100': 'hsl(220, 27%, 96%, 1)',
  'blue-20-66-20': 'hsl(222, 20%, 66%, 0.2)',
  'blue-20-66-100': 'hsl(222, 20%, 66%, 1)',
  'blue-63-29-100': 'hsl(222, 63%, 29%, 1)',
  'blue-31-23-100': 'hsl(223, 31%, 23%, 1)',
  'blue-41-33-50': 'hsl(223, 41%, 33%, 0.5)',
  'blue-41-33-100': 'hsl(223, 41%, 33%, 1)',
  'blue-51-43-100': 'hsl(223, 51%, 43%, 1)',
  'blue-62-28-100': 'hsl(223, 62%, 28%, 1)',
  'blue-53-68-100': 'hsl(220, 53%, 68%, 1)',
  'blue-60-97-100': 'hsl(220, 60%, 97%, 1)',
  'blue-68-11-50': 'hsl(226, 68%, 11%, 0.5)',
  'blue-68-11-100': 'hsl(226, 68%, 11%, 1)',
  'brown-100-89-100': 'hsl(40, 100%, 89%, 1)',
  'gray-0-9-100': 'hsl(0, 0%, 9%, 1)',
  'gray-0-14-100': 'hsl(0, 0%, 14%, 1)',
  'gray-0-19-100': 'hsl(0, 0%, 19%, 1)',
  'gray-0-40-50': 'hsl(0, 0%, 40%, 0.5)',
  'gray-0-40-100': 'hsl(0, 0%, 40%, 1)',
  'gray-0-60-100': 'hsl(0, 0%, 60%, 1)',
  'gray-0-68-100': 'hsl(0, 0%, 68%, 1)',
  'gray-0-73-100': 'hsl(0, 0%, 73%, 1)',
  'gray-0-80-10': 'hsl(0, 0%, 80%, 0.1)',
  'gray-0-80-20': 'hsl(0, 0%, 80%, 0.2)',
  'gray-0-80-40': 'hsl(0, 0%, 80%, 0.4)',
  'gray-0-80-50': 'hsl(0, 0%, 80%, 0.5)',
  'gray-0-80-100': 'hsl(0, 0%, 80%, 1)',
  'gray-0-90-50': 'hsl(0, 0%, 90%, 0.5)',
  'gray-0-90-100': 'hsl(0, 0%, 90%, 1)',
  'gray-0-95-100': 'hsl(0, 0%, 95%, 1)',
  'gray-0-96-100': 'hsl(0, 0%, 96%, 1)',
  'green-40-96-100': 'hsl(119, 40%, 96%, 1)',
  'green-41-90-100': 'hsl(120, 41%, 90%, 1)',
  'green-92-23-100': 'hsl(140, 92%, 23%, 1)',
  'green-48-89-100': 'hsl(143, 48%, 89%, 1)',
  'green-33-47-100': 'hsl(144, 33%, 47%, 1)',
  'green-43-47-100': 'hsl(144, 43%, 47%, 1)',
  'green-44-37-100': 'hsl(144, 44%, 37%, 1)',
  'green-54-37-100': 'hsl(144, 54%, 37%, 1)',
  'green-54-47-10': 'hsl(144, 54%, 47%, 0.1)',
  'green-54-47-20': 'hsl(144, 54%, 47%, 0.2)',
  'green-54-47-40': 'hsl(144, 54%, 47%, 0.4)',
  'green-54-47-50': 'hsl(144, 54%, 47%, 0.5)',
  'green-54-47-100': 'hsl(144, 54%, 47%, 1)',
  'green-54-57-100': 'hsl(144, 54%, 57%, 1)',
  'green-64-57-100': 'hsl(144, 64%, 57%, 1)',
  'green-69-47-100': 'hsl(144, 69%, 47%, 1)',
  'green-90-73-100': 'hsl(163, 90%, 73%, 1)',
  'orange-100-57-100': 'hsl(45, 100%, 57%, 1)',
  'red-42-55-20': 'hsl(313, 42%, 55%, 0.2)',
  'red-78-68-100': 'hsl(325, 78%, 68%, 1)',
  'red-72-49-100': 'hsl(353, 72%, 49%, 1)',
  'red-82-49-100': 'hsl(353, 82%, 49%, 1)',
  'red-82-59-10': 'hsl(353, 82%, 59%, 0.1)',
  'red-82-59-20': 'hsl(353, 82%, 59%, 0.2)',
  'red-82-59-80': 'hsl(353, 82%, 59%, 0.8)',
  'red-82-59-90': 'hsl(353, 82%, 59%, 0.9)',
  'red-82-59-100': 'hsl(353, 82%, 59%, 1)',
  'red-82-69-100': 'hsl(353, 82%, 69%, 1)',
  'red-89-50-100': 'hsl(353, 89%, 50%, 1)',
  'red-90-40-100': 'hsl(335, 90%, 40%, 1)',
  'red-90-54-100': 'hsl(335, 90%, 54%, 1)',
  'red-90-56-100': 'hsl(9, 90%, 56%, 1)',
  'red-91-72-100': 'hsl(9, 91%, 72%, 1)',
  'red-92-69-100': 'hsl(353, 92%, 69%, 1)',
  'red-97-87-100': 'hsl(335, 97%, 87%, 1)',
  'red-100-50-10': 'hsl(335, 100%, 50%, 0.1)',
  'red-100-50-20': 'hsl(335, 100%, 50%, 0.2)',
  'red-100-50-100': 'hsl(335, 100%, 50%, 1)',
  'red-100-54-100': 'hsl(9, 100%, 54%, 1)',
  'red-100-60-100': 'hsl(335, 100%, 60%, 1)',
  'red-100-62-100': 'hsl(335, 100%, 62%, 1)',
  'red-100-64-100': 'hsl(335, 100%, 64%, 1)',
  'red-100-66-100': 'hsl(9, 100%, 66%, 1)',
  'red-100-74-100': 'hsl(335, 100%, 74%, 1)',
  'pink-82-65-100': 'hsl(337, 82%, 65%, 1)',
  'pink-92-65-100': 'hsl(337, 92%, 65%, 1)',
  'red-100-76-100': 'hsl(9, 100%, 76%, 1)',
  'red-100-79-100': 'hsl(9, 100%, 79%, 1)',
  'red-100-95-100': 'hsl(357, 100%, 95%, 1)',
  'red-64-89-100': 'hsl(360, 64%, 89%, 1)',
  'violet-76-90-100': 'hsl(211, 76%, 90%, 1)',
  'violet-98-71-100': 'hsl(274, 100%, 79%, 1)',
  white: 'hsl(0, 0%, 100%, 1)',
  'white-0-74-100': 'hsl(0, 0%, 74%, 1)',
  'white-0-84-100': 'hsl(0, 0%, 84%, 1)',
  'white-0-85-60': 'hsl(0, 0%, 85%, 0.6)',
  'white-0-90-60': 'hsl(0, 0%, 90%, 0.6)',
  'white-0-94-100': 'hsl(0, 0%, 94%, 1)',
  'white-0-95-60': 'hsl(0, 0%, 95%, 0.6)',
  'white-0-100-10': 'hsl(0, 0%, 100%, 0.1)',
  'white-0-100-20': 'hsl(0, 0%, 100%, 0.2)',
  'white-0-100-30': 'hsl(0, 0%, 100%, 0.3)',
  'white-0-100-40': 'hsl(0, 0%, 100%, 0.4)',
  'white-0-100-45': 'hsl(0, 0%, 100%, 0.45)',
  'white-0-100-50': 'hsl(0, 0%, 100%, 0.5)',
  'white-0-100-80': 'hsl(0, 0%, 100%, 0.8)',
  'white-0-100-90': 'hsl(0, 0%, 100%, 0.9)',
  'yellow-45-94-100': 'hsl(36, 45%, 94%, 1)',
  'yellow-100-89-100': 'hsl(51, 100%, 89%, 1)',
  'yellow-90-47-100': 'hsl(45, 90%, 47%, 1)',
  'yellow-100-57-100': 'hsl(45, 100%, 57%, 1)',
  'yellow-100-67-100': 'hsl(45, 100%, 67%, 1)',
  'yellow-100-83-100': 'hsl(45, 100%, 83%, 1)',
};
