import { useCallback, useEffect, useMemo, useRef } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { EVENT_NAMES } from '@config/dom';
import { addEvent } from '@helpers/dom';
import { realtimeIsConnected } from '@helpers/realtime';
import { RealtimeEventHandler } from '@realtime/realtime-event-handler';
import { realtimeGetQueueUpdates } from '@services/realtime/realtime-get-queue-updates';
import { AppFC, REALTIME_HUB_METHOD_NAME, RealtimeEventKey } from '@types';
import { accountGetAccountId, accountGetIsAuthorized } from '@selectors/account';
import { realtimeGetStatus } from '@selectors/realtime';
import { usePaymentsPayWindow } from '@hooks/payments/payments-pay-window';
import { useRealtime } from '@hooks/realtime';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedDispatch, useTypedSelector } from '@hooks/store';
export const RealtimeManager: AppFC = () => {
  const storage = useStorage();
  const navigator = useNavigator();
  const realtime = useRealtime();
  const dispatch = useTypedDispatch();
  const paymentsPayWindow = usePaymentsPayWindow();
  const realtimeEventHandler = useMemo(() => new RealtimeEventHandler({
    storage,
    navigator,
    dispatch,
    paymentsPayWindow
  }), [dispatch, navigator, paymentsPayWindow, storage]);
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const currentMemberId = useTypedSelector(accountGetAccountId);
  const prevRealtimeEventKeyRef = useRef<undefined | RealtimeEventKey>();
  const realtimeStatus = useTypedSelector(realtimeGetStatus);
  const _realtimeIsConnected = realtimeIsConnected(realtimeStatus);
  const handleRealtimeEventQueue = useCallback(async (key: RealtimeEventKey) => {
    const data = await realtimeGetQueueUpdates({
      key
    }).promise;

    /**
     * Start from 1 index here to exclude reealtime event with requested key.
     */
    for (let i = 0; i < data.length; i++) {
      realtimeEventHandler.receiveEvent(data[i].realTimeEvent);
      if (i === data.length - 1) {
        prevRealtimeEventKeyRef.current = data[i].realTimeEvent.key;
      }
    }
  }, [realtimeEventHandler]);
  useEffect(() => {
    return realtime.on(REALTIME_HUB_METHOD_NAME.RECEIVE_EVENT,
    //
    async event => {
      if (!prevRealtimeEventKeyRef.current) {
        prevRealtimeEventKeyRef.current = event.prevKey;
      }
      if (event.prevKey !== prevRealtimeEventKeyRef.current) {
        await handleRealtimeEventQueue(prevRealtimeEventKeyRef.current);
        return;
      }
      prevRealtimeEventKeyRef.current = event.key;
      realtimeEventHandler.receiveEvent(event);
    });
  }, [handleRealtimeEventQueue, realtime, realtimeEventHandler]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(window,
    //
    EVENT_NAMES.VISIBILITYCHANGE, () => {
      if (document.hidden) {
        return;
      }
      if (realtime.state === HubConnectionState.Disconnected) {
        realtime.start();
      }
    });
  }, [isAuthorized, realtime]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(window,
    //
    EVENT_NAMES.FOCUS, () => {
      if (realtime.state === HubConnectionState.Disconnected) {
        realtime.start();
      }
    });
  }, [isAuthorized, realtime]);

  /**
   * If a user was unauthorized/authorized with a different account
   * e.g. in a neighbor tab, the app will restart realtime and reset
   * prevRealtimeEventKeyRef to correctly refetch the realtime event queue.
   */
  useEffect(() => {
    const restartRealtime = async () => {
      if (!currentMemberId) {
        if (realtime.state === HubConnectionState.Connected) {
          await realtime.stop();
          prevRealtimeEventKeyRef.current = undefined;
        }
        return;
      }
      if (realtime.state === HubConnectionState.Disconnected) {
        await realtime.start();
        return;
      }
      if (realtime.state === HubConnectionState.Connected) {
        await realtime.stop();
        prevRealtimeEventKeyRef.current = undefined;
        await realtime.start();
        return;
      }
    };
    restartRealtime();
  }, [currentMemberId, realtime]);
  useEffect(() => {
    if (!_realtimeIsConnected || !prevRealtimeEventKeyRef.current) {
      return;
    }
    handleRealtimeEventQueue(prevRealtimeEventKeyRef.current);
  }, [_realtimeIsConnected, handleRealtimeEventQueue]);
  return null;
};