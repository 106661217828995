import { AUTH_TOKEN_TYPE } from '@config/auth';
import { EVENT_NAMES } from '@config/dom';
import { HTTP_HEADER_NAME, HTTP_STATUS_CODE } from '@config/net';
import { dispatchCustomEvent } from '@helpers/dom';
import { ILocalStorage, initializeStorage } from '@storage';
import { readAccessToken, readRefreshToken, removeStorageAuthData, writeStorageAuthData } from '@storage/auth';
import { authRefreshToken } from '@services/auth/auth-refresh-token';
import { IAuthRefreshState, initializeRefreshState } from '@services/base/auth-refresh-state';
import { httpFetch, HttpFetchCanceledError, HttpFetchResponse } from '@services/base/http-fetch';
import { AuthedHttpFetchConfig, AuthedHttpFetchHeaders, AuthedHttpFetchOwnHeaders, AuthedHttpFetchResult } from './types';
const AUTHED_HTTP_FETCH_MAX_ATTEMPTS_NUMBER = 1;
const _authedHttpFetch = <R, D = undefined, P = undefined, H extends AuthedHttpFetchHeaders = undefined>(url: string, config: undefined | AuthedHttpFetchConfig<D, P, H>, options: {
  forceStorageToken: boolean;
  attemptsNumber: number;
  maxAttemptsNumber: number;
}, storage: ILocalStorage, authRefreshState: IAuthRefreshState): AuthedHttpFetchResult<R> => {
  const accessToken = readAccessToken(storage);
  const authedHttpOwnHeaders = {
    [HTTP_HEADER_NAME.AUTHORIZATION]: `${AUTH_TOKEN_TYPE} ${accessToken}`
  };
  const nextConfig = {
    ...config,
    headers: {
      ...authedHttpOwnHeaders,
      ...config?.headers,
      ...(options?.forceStorageToken ? authedHttpOwnHeaders : undefined)
    }
  };
  const {
    promise,
    cancel
  } = httpFetch<R, D, P, AuthedHttpFetchOwnHeaders | (AuthedHttpFetchOwnHeaders & H)>(url, nextConfig);
  return {
    promise: promise.catch(async (response: HttpFetchResponse<R>) => {
      if (response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
        const attemptsNumber = options.attemptsNumber;
        if (attemptsNumber >= options.maxAttemptsNumber) {
          removeStorageAuthData(storage);
          dispatchCustomEvent(window, EVENT_NAMES.AUTH_REFRESH, undefined);
          authRefreshState.rejectPendingPromises();
          throw response;
        }
        if (authRefreshState.getPending() && attemptsNumber === 0) {
          await authRefreshState.addPendingPromise();
          return _authedHttpFetch<R, D, P, H>(url,
          //
          config, {
            forceStorageToken: true,
            attemptsNumber: attemptsNumber + 1,
            maxAttemptsNumber: options.maxAttemptsNumber
          }, storage, authRefreshState).promise;
        }
        const refreshToken = readRefreshToken(storage);
        if (!refreshToken) {
          throw response;
        }
        authRefreshState.addPendingPromise() //
        .catch(() => {
          /**
           * The catch prevents throwing of unhandled promise rejection when
           * rejectPendingPromises() is called inside authRefreshToken.catch()
           */
        });
        return authRefreshToken({
          refreshToken
        }).promise //
        .catch((response: HttpFetchResponse<R>) => {
          removeStorageAuthData(storage);
          dispatchCustomEvent(window, EVENT_NAMES.AUTH_REFRESH, undefined);
          authRefreshState.rejectPendingPromises();
          throw response;
        }).then(({
          data
        }) => {
          writeStorageAuthData(storage, data);
          dispatchCustomEvent(window, EVENT_NAMES.AUTH_REFRESH, data);
          return _authedHttpFetch<R, D, P, H>(url,
          //
          config, {
            forceStorageToken: true,
            attemptsNumber: attemptsNumber + 1,
            maxAttemptsNumber: options.maxAttemptsNumber
          }, storage, authRefreshState).promise.then(data => {
            authRefreshState.resolvePendingPromises();
            return data;
          });
        });
      }
      throw response;
    }),
    cancel
  };
};
export const authedHttpFetch = <R, D = undefined, P = undefined, H extends AuthedHttpFetchHeaders = undefined>(url: string, config?: AuthedHttpFetchConfig<D, P, H>): AuthedHttpFetchResult<R> => {
  const storage = initializeStorage();
  const authRefreshState = initializeRefreshState();
  const {
    promise,
    cancel
  } = _authedHttpFetch<R, D, P, H>(url,
  //
  config, {
    forceStorageToken: false,
    attemptsNumber: 0,
    maxAttemptsNumber: AUTHED_HTTP_FETCH_MAX_ATTEMPTS_NUMBER
  }, storage, authRefreshState);
  return {
    promise: promise,
    cancel
  };
};
export const AuthedHttpFetchCanceledError = HttpFetchCanceledError;