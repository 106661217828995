import { readRefreshToken } from '@storage/auth';
import { accountLogoffMe } from '@services/account/account-logoff-me';
import { accountLogout } from '@actions/account/account-logout';
import { realtimeFetchOffline } from '@actions/realtime/realtime-offline';
import { AccountFetchLogoff } from './types';
export const accountFetchLogoff: AccountFetchLogoff = payload => {
  return async dispatch => {
    const {
      storage,
      navigator
    } = payload;
    try {
      await Promise.all([dispatch(realtimeFetchOffline({
        storage,
        navigator
      })), Promise.resolve(async () => {
        const refreshToken = readRefreshToken(storage);
        if (refreshToken) {
          await accountLogoffMe({
            refreshToken
          }).promise;
        }
      })]);
    } finally {
      dispatch(accountLogout());
    }
  };
};