import { QUERY_PARAM } from '@config/routing';
import { Route } from './route';
export const ROUTES = {
  /**
   * This is an example that shows how to use custom routing and rewrites.
   * Rewrites are necessary due to inner next router mechanics.
   * See rewrite rules in next.config.js
   *
   * posts: {
   *   external: new Route<{
   *     postId: string;
   *     culture?: string;
   *     name?: string;
   *     pid?: string;
   *   }>({
   *     pattern: '/:culture/server-routing/posts/:postId/:name/:pid',
   *     page: '/posts/[postId]',
   *   }),
   * },
   */

  index: new Route<{
    [QUERY_PARAM.SOURCE]?: string;
    [QUERY_PARAM.REDIRECT_TO]?: string;
    [QUERY_PARAM.STEP]?: string;
  }>({
    pattern: '/',
    page: '/'
  }),
  account: {
    index: new Route({
      pattern: '/account',
      page: '/account'
    }),
    logoff: new Route({
      pattern: '/account/logoff',
      page: '/account/logoff'
    })
  },
  clients: {
    id: new Route<{
      clientId: string;
      culture?: string;
      name?: string;
      pid?: string;
    }>({
      pattern: '/clients/:clientId',
      page: '/clients/[clientId]'
    })
  },
  psychologists: {
    id: new Route<{
      specialistId: string;
      culture?: string;
      name?: string;
      pid?: string;
    }>({
      pattern: '/psychologists/:specialistId',
      page: '/psychologists/[specialistId]'
    })
  },
  chats: {
    index: new Route({
      pattern: '/chats',
      page: '/chats'
    }),
    id: new Route<{
      chatId: string;
    }>({
      pattern: '/chats/:chatId/messages',
      page: '/chats/[chatId]/messages'
    })
  },
  invitations: {
    index: new Route({
      pattern: '/invitations',
      page: '/invitations'
    })
  },
  search: {
    index: new Route({
      pattern: '/search',
      page: '/search'
    })
  },
  top100: {
    index: new Route({
      pattern: '/top100',
      page: '/top100'
    })
  },
  views: {
    index: new Route({
      pattern: '/views',
      page: '/views'
    })
  },
  /**
   * Order is important here. A more specific route must be first.
   */
  profile: {
    index: new Route({
      pattern: '/profile',
      page: '/profile'
    }),
    edit: {
      index: new Route({
        pattern: '/profile/edit',
        page: '/profile/edit'
      })
    },
    id: new Route<{
      userid: string;
    }>({
      pattern: '/profile/:userid',
      page: '/profile/[userid]'
    })
  },
  about: {
    index: new Route({
      pattern: '/about',
      page: '/about'
    })
  },
  callSetup: {
    index: new Route({
      pattern: '/callsetup',
      page: '/callsetup'
    })
  },
  contacts: {
    index: new Route({
      pattern: '/contacts',
      page: '/contacts'
    })
  },
  tariffs: {
    index: new Route({
      pattern: '/tariffs',
      page: '/tariffs'
    })
  },
  clientUserAgreement: {
    index: new Route({
      pattern: '/client-user-agreement',
      page: '/client-user-agreement'
    }),
    en: {
      index: new Route({
        pattern: '/client-user-agreement/en',
        page: '/client-user-agreement/en'
      })
    }
  },
  specialistUserAgreement: {
    index: new Route({
      pattern: '/specialist-user-agreement',
      page: '/specialist-user-agreement'
    }),
    en: {
      index: new Route({
        pattern: '/specialist-user-agreement/en',
        page: '/specialist-user-agreement/en'
      })
    }
  },
  privacyPolicy: {
    index: new Route({
      pattern: '/privacy-policy',
      page: '/privacy-policy'
    }),
    en: {
      index: new Route({
        pattern: '/privacy-policy/en',
        page: '/privacy-policy/en'
      })
    },
    russians: {
      index: new Route({
        pattern: '/privacy-policy/citizens-of-the-russian-federation',
        page: '/privacy-policy/citizens-of-the-russian-federation'
      })
    }
  },
  psychologist: {
    index: new Route<{
      [QUERY_PARAM.SOURCE]?: string;
    }>({
      pattern: '/psychologist',
      page: '/psychologist'
    })
  },
  calendar: {
    index: new Route({
      pattern: '/calendar',
      page: '/calendar'
    }),
    approve: new Route<{
      consultationId: string;
    }>({
      pattern: '/calendar/consultations/:consultationId/approve',
      page: '/calendar/consultations/[consultationId]/approve'
    }),
    reject: new Route<{
      consultationId: string;
    }>({
      pattern: '/calendar/consultations/:consultationId/reject',
      page: '/calendar/consultations/[consultationId]/reject'
    })
  },
  emails: {
    unsubscribed: {
      index: new Route({
        pattern: '/emails/unsubscribed',
        page: '/emails/unsubscribed'
      })
    }
  },
  lp: new Route<{
    [QUERY_PARAM.SOURCE]?: string;
    [QUERY_PARAM.REDIRECT_TO]?: string;
  }>({
    pattern: '/lp',
    page: '/lp'
  }),
  homePage: {
    index: new Route<{
      [QUERY_PARAM.SOURCE]?: string;
    }>({
      pattern: '/homepage',
      page: '/homepage'
    })
  },
  sexologistsPage: {
    index: new Route<{
      [QUERY_PARAM.SOURCE]?: string;
    }>({
      pattern: '/sexologists',
      page: '/sexologists'
    })
  },
  PsychologyPage: {
    index: new Route<{
      [QUERY_PARAM.SOURCE]?: string;
    }>({
      pattern: '/landingpsy1',
      page: '/landingpsy1'
    })
  },
  termsForEuResidents: {
    index: new Route({
      pattern: '/terms-for-eu-residents',
      page: '/terms-for-eu-residents'
    })
  },
  rightOfWithdrawalForEuResidents: {
    index: new Route({
      pattern: '/right-of-withdrawal-for-eu-residents',
      page: '/right-of-withdrawal-for-eu-residents'
    })
  }
};