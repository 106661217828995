export enum SEVERITY {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
  ANALYTICS = 'ANALYTICS',
}
export enum ANALYTICS_ACTION {
  PAGEVIEW = 'PAGEVIEW',
  MODAL_VIEW = 'MODAL_VIEW',
  VISIT = 'VISIT',
  SESSION = 'SESSION',
  BUY_COINS_TRY = 'BUY_COINS_TRY',
  PREMIUM_SHOW_INFO = 'PREMIUM_SHOW_INFO',
  BUY_PREMIUM_TRY = 'BUY_PREMIUM_TRY',
  FILL_PROFILE = 'FILL_PROFILE',
  LP_CLICK = 'LP_CLICK',
  T1_NOTIFICATION_LINK_VISITED = 'T1_NOTIFICATION_LINK_VISITED',
  T5_NOTIFICATION_LINK_VISITED = 'T5_NOTIFICATION_LINK_VISITED',
  UI_CLICK = 'UI_CLICK',
  VIDEO_VIEW_PROGRESS = 'VIDEO_VIEW_PROGRESS',
}
export enum ANALYTICS_EVENT_TYPE {
  LP_CLICK = 'LP_CLICK',
  //not using on the backend for now
  FILL_PROFILE = 3,
  BUY_COINS_TRY = 6,
  PREMIUM_SHOW_INFO = 11,
  VISIT = 29,
  BUY_PREMIUM_TRY = 30,
  UI_CLICKED = 35,
  VIDEO_VIEW_PROGRESS = 41,
  PAGE_VIEW = 42,
  MODAL_VIEW = 49,
}
export enum ANALYTICS_SUPPORT_EVENT_TYPE {
  T1_NOTIFICATION_LINK_VISITED = 32,
  T5_NOTIFICATION_LINK_VISITED = 43,
}
export enum ANALYTICS_PAGE_ID {
  ABOUT = 'About',
  AGREEMENT_CLIENT = 'Agreement.Client',
  AGREEMENT_SPECIALIST = 'Agreement.Specialist',
  CALL_SETUP = 'CallSetup',
  CHATS = 'Chats',
  CALENDAR = 'Calendar',
  CONTACTS = 'Contacts',
  INVITATIONS = 'Invitations',
  LP1 = 'LP1',
  MAIN = 'Main',
  MAIN_WITH_DIRECT_TO_SEARCH = 'MainLandingDirectToSearch',
  LANDING_SEXOLOGISTS = 'LandingSexologists',
  LANDING_PSY_1 = 'LandingPsy1',
  ME_ACCOUNT = 'Me.Account',
  ME_PROFILE_EDIT = 'Me.Profile.Edit',
  PRIVACY = 'Privacy',
  PRIVACY_RF = 'Privacy.RF',
  PROFILE_CLIENT = 'Profile.Client',
  PROFILE_SPECIALIST = 'Profile.Specialist',
  SEARCH = 'Search',
  SINGLE_CHAT = 'SingleChat',
  SPECIALIST_LP = 'SpecialistLP',
  TARIFFS = 'Tariffs',
  TOP100 = 'TOP100',
  VIEWS = 'Views',
}