import { enforceDateStringToISO } from '@helpers/date';
import { isFreeConsultant } from '@helpers/member';
import { SpecialistsGetTopRequestData, SpecialistsGetTopRequestParams, SpecialistsGetTopResponseData, SpecialistsGetTopResponseDto } from '@services/dto/specialists';
export const specialistsGetTopRequestToParams = (data: SpecialistsGetTopRequestData): SpecialistsGetTopRequestParams => {
  return {
    limit: data.limit,
    offset: data.offset
  };
};
export const specialistsGetTopResponseFromDto = (dto: SpecialistsGetTopResponseDto): SpecialistsGetTopResponseData => {
  return {
    ...dto.data,
    items: dto.data.items.map(item => {
      const _isFreeConsultant = isFreeConsultant(item.profSpeciality);
      return {
        ...item,
        lastActivityTime: item.lastActivityTime ? enforceDateStringToISO(item.lastActivityTime) : undefined,
        age: _isFreeConsultant //
        ? undefined : item.age,
        defaultSubjectName: _isFreeConsultant ? undefined : item.defaultSubjectName,
        subjectsCount: _isFreeConsultant //
        ? 0 : item.subjectsCount,
        rating: _isFreeConsultant //
        ? undefined : item.rating
      };
    })
  };
};